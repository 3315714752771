import PropTypes from 'prop-types'
import React from 'react'
import { getAsciiTable } from '../../util'

import './AsciiTable.scss'

class AsciiTable extends React.Component {
  render() {
    const asciiTable = getAsciiTable(+this.props.start, +this.props.end)
    const showLinks = this.props.showLinks || false

    const rows = []
    asciiTable.forEach((data) => {
      let path = `/ascii-value-of-${data['url']}/`
      rows.push(
        <tr key={data['decimal']}>
          <td className="key">
            <span>{data['decimal']}</span>
          </td>
          <td>{data['hex']}</td>
          <td>{data['octal']}</td>
          <td>{data['binary']}</td>
          <td className="value">
            {showLinks && data['url'] ? (
              <a href={path}>
                <span>{data['char']}</span>
              </a>
            ) : (
              <span>{data['char']}</span>
            )}
          </td>
          <td>{data['description']}</td>
        </tr>
      )
    })

    return (
      <div className="ascii-table">
        <table>
          <thead>
            <tr>
              <th>Dec</th>
              <th>Hex</th>
              <th>Octal</th>
              <th>Binary</th>
              <th>Char</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }
}

export default AsciiTable

AsciiTable.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
}

AsciiTable.defaultProps = {
  start: 0,
  end: 127,
}
