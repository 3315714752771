import React from 'react'
import AsciiTable from '../AsciiTable'
import Container from '../Container'

import './Product.scss'

class Product extends React.Component {
  render() {
    return (
      <div className="product">
        <Container>
          <div className="product-container">
            <h1 className="product-heading">ASCII Table Online</h1>
            <div className="product-summary">
              <p>
                <strong>ASCII</strong> stands for <strong>A</strong>merican{' '}
                <strong>S</strong>tandard <strong>C</strong>ode for{' '}
                <strong>I</strong>nformation <strong>E</strong>xchange. It is a
                character encoding standard developed in the early 60’s for
                representing and exchanging arbitrary text-based information
                between computers. It encodes 128 specified characters into
                7-bit integers as shown in the table below...{' '}
                <a href="#blog-about">Learn more</a>
              </p>
            </div>
            <AsciiTable start={0} end={127} showLinks={true} />
          </div>
        </Container>
      </div>
    )
  }
}

export default Product
