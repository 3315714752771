import React from 'react'
import Container from '../Container'
import AsciiTable from '../AsciiTable'
import Adsense from '../Adsense'

import './Product.scss'

class ProductExtended extends React.Component {
  render() {
    return (
      <div className="product">
        <Container>
          <div className="product-container">
            <h1 className="product-heading">Extended ASCII Table Online</h1>
            <div className="product-summary">
              <p>
                Extended ASCII table, as the name suggests, is an extended
                version of the original ASCII table. It uses 8-bits and consists
                of encodings for characters in the range 0 - 255.
              </p>
              <p>
                The first part of the extended ASCII table (0 - 127) is same as
                the original <a href="/">ASCII table</a>. The next part (128 -
                255) has several variations. The table below is according to
                Windows-1252 character set which is a super set of ISO-8859-1.
                In ISO-8859-1, the characters from 128 to 159 are not defined.
                However, In Windows-1252, the characters from 128 to 159 are
                used for some useful symbols as displayed in the following
                table.
              </p>
            </div>
            <AsciiTable start="128" end="255" />
          </div>
        </Container>
      </div>
    )
  }
}

export default ProductExtended
